import {
  FlowEditorSDK,
  PlatformControllerFlowAPI,
} from '@wix/yoshi-flow-editor';

export const richTextFormatter = (html: string = '') => {
  const tags = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'ul', 'ol'];
  const formattedHtml = tags.reduce((prev = '', tag) => {
    const tempString = prev
      ?.toString()
      ?.replaceAll?.(
        `<${tag}>`,
        `<${tag} style="color: inherit;font-family: inherit;font-size: inherit;text-align: inherit;letter-spacing: inherit;line-height: inherit;">`,
      )
      ?.toString();
    return tempString;
  }, html || '');
  return formattedHtml;
};

export const getPageViewMode = (flowAPI: PlatformControllerFlowAPI) => {
  let viewMode = '';
  const { isViewer, isEditor, isPreview } = flowAPI.environment;
  if (isViewer) {
    viewMode = 'liveSite';
  } else if (isEditor) {
    viewMode = 'editor';
  } else if (isPreview) {
    viewMode = 'preview';
  }

  return viewMode;
};

export const isSeoTagsEnabled = (flowApi: PlatformControllerFlowAPI) => {
  const enableSeoTags = flowApi.experiments.enabled(
    'specs.portfolio.enableSeoTags',
  );
  return enableSeoTags;
};
