import model from './model';
import {
  ProjectDetailsWidgetComponentIds as ComponentIds,
  ProjectDetailsWidgetComponentIds,
} from './config/constants';
import { ProjectDetailsWidgetProps as Props } from './config/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  let components: ReturnType<typeof getComponents>;
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    text: $w(ComponentIds.Text),
    label: $w(ComponentIds.Label),
    repeater: $w(ComponentIds.Repeater),
  });

  const initByProps = (props: Props) => {
    const { data } = props;
    components?.repeater.onItemReady(($item, itemData, index) => {
      $item(ProjectDetailsWidgetComponentIds.Text).text = itemData.text;
      $item(ProjectDetailsWidgetComponentIds.Label).text = itemData.label;
    });
    data.projectDetails.forEach(
      // @ts-expect-error
      (item, index) => (item._id = index.toString()),
    );
    components && (components.repeater.data = data.projectDetails);
  };

  $widget.onPropsChanged((_, nextProps) => {
    components = getComponents();
    const isWidgetOnStage = !!components.topContainer.id;
    if (isWidgetOnStage) {
      initByProps(nextProps);
    }
  });

  return {
    pageReady: async () => { },
    exports: {},
  };
});
