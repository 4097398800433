import model from './model';
import { FeaturedCollectionsWidgetComponentIds as ComponentIds } from './config/constants';
import { Collection } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { Project } from '@wix/ambassador-portfolio-projects-v1-project/types';
import {
  getCollectionProjects,
  getCollectionsWithHidden,
} from '../../services/dataServices';
import { WarmupDataManager } from '../../utils/WarmupDataManager';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { httpClient } = flowAPI;
  const { isSSR } = flowAPI.environment;
  const shouldShowEmptyComponent = isSSR;

  let components: ReturnType<typeof getComponents>;
  let collections: Collection[] = [];
  let collectionProjects: Project[] = [];

  const warmupData = new WarmupDataManager(
    flowAPI.controllerConfig.wixCodeApi.window.warmupData,
    flowAPI.environment,
    flowAPI.experiments.enabled('specs.portfolio.warmupDataPortfolio'),
  );

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBoxWidget: $w(ComponentIds.MultiStateWidget),
  });

  const getArrayOfSelectedCollections = () => {
    let filteredCollections: Collection[] = [];
    const selectedCollectionIds = $widget.props.data.selectedCollections;
    if (!selectedCollectionIds) {
      filteredCollections = [collections[0]];
    } else {
      filteredCollections = collections.filter((collection: Collection) => {
        const isCollectionSelected = selectedCollectionIds.includes(
          collection.id,
        );
        return isCollectionSelected;
      });
    }
    return filteredCollections;
  };

  const initFeaturedWidgetData = async () => {
    const selectedCollectionsDataArray = getArrayOfSelectedCollections();
    if (selectedCollectionsDataArray.length === 1) {
      const selectedCollection = selectedCollectionsDataArray[0];
      let projectsInCollection;
      if (warmupData.isExperimentEnabled) {
        projectsInCollection = await warmupData.manageData(
          () =>
            getCollectionProjects(httpClient, selectedCollection.id as string),
          `${selectedCollection.id}-Projects`,
        );
      } else {
        projectsInCollection = await getCollectionProjects(
          httpClient,
          selectedCollection.id as string,
        );
      }
      collectionProjects = projectsInCollection.map(
        (p) => p.project,
      ) as Project[];
    }
    components.multiStateBoxWidget.data = {
      collections: selectedCollectionsDataArray,
      collectionProjects,
    };
  };
  const initEmptyCollection = async () => {
    components.multiStateBoxWidget.data = { collections: [] };
  };
  const initFeaturedWidget = async () => {
    try {
      if (warmupData.isExperimentEnabled) {
        collections = await warmupData.manageData(
          () => getCollectionsWithHidden(httpClient),
          'featuredCollections',
        );
      } else {
        collections = await getCollectionsWithHidden(httpClient);
      }
      await initFeaturedWidgetData();
    } catch (e) {
      if (shouldShowEmptyComponent) {
        await initEmptyCollection();
      }
    }
  };
  return {
    pageReady: async () => {
      components = getComponents();
      await initFeaturedWidget();
    },
    exports: {},
  };
});
